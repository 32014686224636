<template>
  <div style="background: #FFFFFF; padding: 20px" v-loading="loading">
    <el-form :rules="rules" style="display: grid; grid-template-columns: auto auto auto; gap: 20px" label-position="left"
             :model="addForm" ref="form" size="small">
      <el-form-item :prop="item.fieldType === 'table' ? (item.fieldCode + 'Name') : item.fieldCode" :key="item.id" v-for="item in fields" :label="item.fieldDisplay" v-if="item.fieldType !== 'image'" :style="item.fieldType === 'image' ? { display: 'inline-grid' } : {}">
        <el-input v-if="item.fieldType === 'text'" v-model="addForm[item.fieldCode]"></el-input>
        <el-input v-if="item.fieldType === 'number'" type="number" v-model="addForm[item.fieldCode]"></el-input>
        <el-input @focus="openLocationPicker(item.fieldCode)" v-if="item.fieldType === 'location'"
                  v-model="addForm[item.fieldCode]"></el-input>
        <el-select placeholder="" style="width: 100%" v-if="item.fieldType === 'enum'" v-model="addForm[item.fieldCode]">
          <el-option v-for="option in item.links" :key="option.value" :label="option.label"
                     :value="option.value"></el-option>
        </el-select>
        <el-input v-show="false" @focus="openLinkDialog(item.link, item.fieldCode, item.linkField)"
                  suffix-icon="el-icon-s-grid" v-if="item.fieldType === 'table'" v-model="addForm[item.fieldCode]"/>
        <el-input @focus="openLinkDialog(item.link, item.fieldCode, item.linkField)" prefix-icon="el-icon-s-grid"
                  v-if="item.fieldType === 'table'" v-model="addForm[item.fieldCode + 'Name']"/>
        <el-date-picker
          value-format="yyyy-MM-dd"
          style="width: 100%" v-if="item.fieldType === 'date'"
          v-model="addForm[item.fieldCode]"
          type="date"
          placeholder="">
        </el-date-picker>
      </el-form-item>

    </el-form>
    <el-form style="display: grid; grid-template-columns: auto auto auto; gap: 20px; margin-top: 20px" label-position="left"
             :model="addForm" size="small">
      <el-form-item :key="item.id" v-for="item in fields" :label="item.fieldDisplay" v-if="item.fieldType === 'image'" :style="item.fieldType === 'image' ? { display: 'inline-grid' } : {}">
        <el-upload
          :action="apiBaseURL() + '/webuploader/upload'"
          :headers="{
                        token: getToken()
                      }"
          accept="image/png, image/jpeg"
          :before-upload="function (file) { return beforeUploadImg(file, item.fieldCode)}"
          :on-success="uploadImgSuccess"
          :on-remove="onImgRemove"
          v-if="item.fieldType === 'image'"
          list-type="picture-card">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <div style="text-align: right">
      <el-button size="small" type="primary" @click="onSubmit">录入数据</el-button>
      <el-button size="small" @click="$router.back()">取消</el-button>
    </div>

    <el-dialog
      title="选择数据"
      :visible.sync="linkDialog"
      width="1000px">
      <div style="gap: 10px;display: flex; margin-bottom: 10px">
        <el-input style="width: 300px" v-model="linkKeyword" size="small" placeholder="输入关键词检索"></el-input>
        <el-button size="small" type="primary" @click="searchLinkData">搜索</el-button>
      </div>
      <el-table
        size="small"
        @row-click="rowClick"
        v-if="fields.length > 0"
        v-loading="linkDataLoading"
        :data="dataList"
        border
        style="width: 100%">
        <el-table-column
          v-if="linkId !== 'customer'"
          prop="id"
          width="200"
          label="编号">
        </el-table-column>
        <el-table-column
          v-for="item in linkFields"
          :key="item.id"
          :label="item.fieldDisplay">
          <template #default="{ row }">
            {{ row.dataValue[item.fieldCode] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="creatorName"
          width="200"
          label="创建人">
        </el-table-column>
        <el-table-column
          prop="createDate"
          width="200"
          label="创建日期">
        </el-table-column>
      </el-table>
      <div v-else>
        <div style="text-align: center; color: rgb(128,128,128); margin-bottom: 100px; margin-top: 100px">
          请先创建字段
        </div>
      </div>
      <div style="text-align: right; margin-top: 20px">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[1, 2, 3, 10, 30, 50, 100, 200, 300, 500]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </el-dialog>

    <el-dialog
      title="选择位置"
      :visible.sync="locationPickerDialog"
      top="5vh"
      width="1000px">
      <div v-loading="locationPickerLoading" style="height: 70vh; width: 100%" id="location-picker"></div>
      <div style="text-align: center; margin-top: 30px">
        <span v-loading="locationPickerLoading"
              style="background: #3472c7; color: #FFFFFF; padding: 12px 30px; border-radius: 4px; font-weight: bold">{{
            locationPickerAddress
          }}</span>
        <el-button @click="confirmLocation" type="success" style="margin-left: 20px">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import {getFields} from '@/api/expandTableFields'
import {addData, fetchData} from '@/api/expandTableValue'
import {apiBaseURL} from "@/utils/request";
import {getToken} from "@/utils/auth";

export default {
  name: 'name',
  data() {
    return ({
      tableId: '',
      loading: false,
      fields: [],
      addForm: {},
      queryForm: {},
      linkDialog: false,
      linkId: '',
      linkDataLoading: true,
      linkFields: [],
      dataList: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      pos: '',
      linkField: '',
      locationPickerLoading: false,
      locationPickerDialog: false,
      marker: null,
      locationPickerAddress: '请点击地图选择地点',
      rules: {},
      linkKeyword: ''
    })
  },
  mounted() {
    this.tableId = this.$route.params.id
    this.getFields()
  },
  methods: {
    apiBaseURL() {
      return apiBaseURL
    },
    getToken,
    beforeUploadImg (file, pos) {
      this.pos = pos
    },
    uploadImgSuccess (response, file, fileList) {
      // console.log(fileList)
      var imgArray = []
      for (var i = 0; i < fileList.length; i++) {
        imgArray.push(fileList[i].response)
      }
      this.addForm[this.pos] = imgArray
    },
    onImgRemove (file, fileList) {
      // console.log(fileList)
      var imgArray = []
      for (var i = 0; i < fileList.length; i++) {
        imgArray.push(fileList[i].response)
      }
      this.addForm[this.pos] = imgArray
    },
    confirmLocation() {
      // 动态添加 address 属性到 addForm 中
      if (!this.addForm.hasOwnProperty('address')) {
        Vue.set(this.addForm, 'address', this.locationPickerAddress);
      } else {
        this.addForm['address'] = this.locationPickerAddress;
      }

      // 动态添加 lat_long 属性到 addForm 中
      if (!this.addForm.hasOwnProperty('lat_long')) {
        Vue.set(this.addForm, 'lat_long', this.marker['_position'][0] + ',' + this.marker['_position'][1]);
      } else {
        this.addForm['lat_long'] = this.marker['_position'][0] + ',' + this.marker['_position'][1];
      }

      this.locationPickerDialog = false;

      // 触发校验
      this.$nextTick(() => {
        this.$refs.form.validateField('address');
        this.$refs.form.validateField('lat_long');
      });
    },
    async openLocationPicker(pos) {
      this.locationPickerDialog = true
      this.locationPickerAddress = '请点击地图选择地点'
      this.pos = pos;
      setTimeout(() => {
        const map = new AMap.Map("location-picker", {
          viewMode: '2D', // 默认使用 2D 模式
          zoom: 13, // 地图级别
          center: [114.51158, 38.044282], // 地图中心点
        });

        map.on("click", (ev) => {
          this.locationPickerLoading = true
          // 使用箭头函数，确保`this`指向Vue实例
          var lnglat = ev.lnglat;
          if (this.marker) {
            map.remove(this.marker);
          }
          this.marker = new AMap.Marker({
            icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
            position: [lnglat.lng, lnglat.lat],
            anchor: 'bottom-center',
          });
          map.add(this.marker);

          AMap.plugin("AMap.Geocoder", () => {
            var geocoder = new AMap.Geocoder();

            geocoder.getAddress(this.marker['_position'], (status, result) => {
              if (status === "complete" && result.info === "OK") {
                // result为对应的地理位置详细信息
                this.locationPickerAddress = result.regeocode.formattedAddress;
                this.locationPickerLoading = false
              } else {
                this.locationPickerLoading = false
                this.$message.error('Network Error')
              }
            });
          });
        });
      }, 100);
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      this.fetchDataList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.fetchDataList()
    },
    rowClick(row) {
      if (!this.addForm.hasOwnProperty(this.pos)) {
        Vue.set(this.addForm, this.pos, row.id);
      } else {
        this.addForm[this.pos] = row.id;
      }

      if (!this.addForm.hasOwnProperty(this.pos + 'Name')) {
        Vue.set(this.addForm, this.pos + 'Name', row.dataValue[this.linkField]);
      } else {
        this.addForm[this.pos + 'Name'] = row.dataValue[this.linkField];
      }

      this.linkDialog = false;

      // 手动触发校验
      this.$nextTick(() => {
        this.$refs.form.validateField(this.pos + 'Name');
      });
    },
    async openLinkDialog(id, pos, linkField) {
      this.dataList = []
      this.linkDataLoading = true
      this.linkKeyword = ''
      this.currentPage = 1
      this.pageSize = 10
      this.linkDialog = true
      this.linkId = id
      this.pos = pos
      this.linkField = linkField
      await this.getLinkFields()
      await this.fetchDataList(true)
    },
    async searchLinkData() {
      this.queryForm[this.linkField] = this.linkKeyword
      this.currentPage = 1
      await this.fetchDataList(false)
    },
    async getLinkFields() {
      const res = await getFields({alias: this.linkId})
      this.linkFields = res.data
    },
    async fetchDataList(clearFilter) {
      this.linkDataLoading = true
      this.dataList = []
      if (clearFilter) {
        this.queryForm = {}
        this.currentPage = 1
      }
      const res = await fetchData({
        alias: this.linkId,
        queryForm: this.queryForm,
        pageSize: this.pageSize,
        currentPage: this.currentPage
      })
      this.linkDataLoading = false
      if (res.code === 0 && res.data.list && res.data.list.length > 0) {
        for (var i = 0; i < res.data.list.length; i++) {
          res.data.list[i].dataValue = JSON.parse(res.data.list[i].recordValue)
        }
        this.dataList = res.data.list
        this.total = res.data.total
      } else {
        this.dataList = []
        this.total = 0
      }
    },
    async getFields() {
      this.loading = true
      const res = await getFields({alias: this.tableId})
      this.loading = false
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].fieldRemark && res.data[i].fieldRemark.indexOf('##default=') !== -1) {
          if (!this.addForm.hasOwnProperty(res.data[i].fieldCode)) {
            Vue.set(this.addForm, res.data[i].fieldCode, res.data[i].fieldRemark.split('##default=')[1].split('$$')[0]);
          } else {
            this.addForm[res.data[i].fieldCode] = res.data[i].fieldRemark.split('##default=')[1].split('$$')[0]
          }
        }
        var links = []
        if (res.data[i].link) {
          let linkSplit = res.data[i].link.split(',')
          for (var j = 0; j < linkSplit.length; j++) {
            var item = {
              label: linkSplit[j].split('->')[1],
              value: linkSplit[j].split('->')[0]
            }
            links.push(item)
          }
          res.data[i].links = links
        }
        if (res.data[i].isRequired && res.data[i].isRequired === '1') {
          const rules = []

          if (res.data[i].fieldType === 'table') {
            rules.push({required: true, message: res.data[i].requiredWarningText, trigger: 'change'})
            this.rules[res.data[i].fieldCode + 'Name'] = rules
          } else {
            rules.push({required: true, message: res.data[i].requiredWarningText, trigger: 'blur'})
            this.rules[res.data[i].fieldCode] = rules
          }
        }
      }
      this.fields = res.data
      console.log(this.rules)
    },
    async onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true
          const res = await addData({alias: this.tableId, recordValue: JSON.stringify(this.addForm)})
          this.loading = false
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.$router.back()
          } else {
            this.$message.error(res.msg)
          }
        }
      })

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
